var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row pt-3"},[_c('div',{class:{
      'col': _vm.showActionBtn,
      'offset-6 col-6': !_vm.showActionBtn,
    }},[_c('button',{staticClass:"btn btn-secondary w-100",on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v(" Cerrar ")])]),(
      _vm.canRejectSecondOpinion && _vm.isSecondOpinion && !_vm.isSecondOpinionConfirmed
    )?_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn w-100 btn-danger",on:{"click":function($event){return _vm.$emit('confirmReject')}}},[_vm._v(" Rechazar ")])]):_vm._e(),(_vm.showActionBtn)?_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn w-100",class:{
        'btn-danger': !_vm.isSecondOpinion && _vm.uploadDataValidation,
        'btn-primary': _vm.isSecondOpinion || !_vm.uploadDataValidation,
      },on:{"click":_vm.actionBtnMethod}},[_vm._v(" "+_vm._s(_vm.actionBtnText)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }