<template>
  <div class="row pt-3">
    <div
      :class="{
        'col': showActionBtn,
        'offset-6 col-6': !showActionBtn,
      }">
      <button class="btn btn-secondary w-100" @click="$emit('closeModal')">
        Cerrar
      </button>
    </div>
    <div
      class="col"
      v-if="
        canRejectSecondOpinion && isSecondOpinion && !isSecondOpinionConfirmed
      ">
      <button class="btn w-100 btn-danger" @click="$emit('confirmReject')">
        Rechazar
      </button>
    </div>
    <div class="col" v-if="showActionBtn">
      <button
        class="btn w-100"
        :class="{
          'btn-danger': !isSecondOpinion && uploadDataValidation,
          'btn-primary': isSecondOpinion || !uploadDataValidation,
        }"
        @click="actionBtnMethod">
        {{ actionBtnText }}
      </button>
    </div>
  </div>
</template>

<script>
import paymentModal from "@/mixin/paymentModal.mixin";

export default {
  mixins: [paymentModal],
  computed: {
    showActionBtn() {
      if (this.isAppointmentNotPayed) return this.canUploadPaymentEvidence;
      if (this.isAppointmentPaidUploaded) return this.canRemovePaymentEvidence;
      if (this.isSecondOpinion && !this.isSecondOpinionConfirmed)
        return this.canConfirmSecondOpinion;
      return false;
    },
    actionBtnText() {
      if (this.isSecondOpinion && !this.isSecondOpinionConfirmed) {
        return "Confirmar";
      }
      return !this.uploadDataValidation ? "Guardar" : "Eliminar";
    },
  },
  methods: {
    actionBtnMethod() {
      if (this.isSecondOpinion && this.canConfirmSecondOpinion) {
        this.$emit("confirmSecondOpinion");
      } else if (this.isAppointmentNotPayed && this.canUploadPaymentEvidence) {
        this.$emit("saveFiles");
      } else if (
        this.isAppointmentPaidUploaded &&
        this.canRemovePaymentEvidence
      ) {
        this.$emit("confirmRemove");
      }
    },
  },
};
</script>

<style></style>
